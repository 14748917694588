<template>
  <nav>
    <router-link to="/">Startseite</router-link> |
    <router-link to="/happiness">Glück</router-link> |
    <router-link to="/health">Gesundheit</router-link> |
    <router-link to="/prosperity">Wohlstand</router-link> |
    <router-link to="/about">Über Mich</router-link>
    <!--    <router-link to="/">Home</router-link> |-->
    <!--    <router-link to="/happiness">Happiness</router-link> |-->
    <!--    <router-link to="/health">Health</router-link> |-->
    <!--    <router-link to="/prosperity">Prosperity</router-link> |-->
    <!--    <router-link to="/about">About Me</router-link>-->
  </nav>
  <router-view />
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #31ffff;
}

nav {
  padding: 30px 0 0 0;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
<script setup lang="ts"></script>
