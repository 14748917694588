
import { defineComponent } from 'vue';
import HelloVictory from '@/components/HelloVictory.vue'; // @ is an alias to /src

export default defineComponent({
  name: 'HomeVictory',
  components: {
    HelloVictory,
  },
});
