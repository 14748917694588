
import { defineComponent } from 'vue';
import HelloProsperity from '../components/HelloProsperity.vue'; // @ is an alias to /src

export default defineComponent({
  name: 'HomeProsperity',
  components: {
    HelloProsperity,
  },
});
