
import { defineComponent } from 'vue';
import HelloHealth from '../components/HelloHealth.vue'; // @ is an alias to /src

export default defineComponent({
  name: 'HomeHealth',
  components: {
    HelloHealth,
  },
});
