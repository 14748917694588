import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import HappinessView from '@/views/HappinessView.vue';
import HealthView from '@/views/HealthView.vue';
import ProsperityView from '@/views/ProsperityView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/happiness',
    name: 'happiness',
    component: HappinessView,
  },
  {
    path: '/health',
    name: 'health',
    component: HealthView,
  },
  {
    path: '/prosperity',
    name: 'prosperity',
    component: ProsperityView,
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
