
import { defineComponent } from 'vue';
import HelloHappiness from '../components/HelloHappiness.vue'; // @ is an alias to /src

export default defineComponent({
  name: 'HomeHappiness',
  components: {
    HelloHappiness,
  },
});
